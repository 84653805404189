.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.ant-modal-body img{
  width: 100%;
}
.ant-modal-body .action--btn{
  background: none;
  border: none;
  outline: none;
  font-size: 20px;
  width: 147px;
  height: 40px;
  border-radius: 10px;
  background-color: #28ac92;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  margin: 10px auto;
  color: #fff;
  display: block;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error{
  height: auto;
}

.ant-upload-list-item-name{
  word-break: break-all;
  white-space: normal;
  height: auto;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

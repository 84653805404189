/* KALATEXA */
@font-face {
  font-family: "KaLaTeXa Display";
  src: url("../public/KaLaTeXaDisplay/KaLaTeXaDisplay-Bold.woff2")
      format("woff2"),
    url("../public/KaLaTeXaDisplay/KaLaTeXaDisplay-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "KaLaTeXa Display bold";
  src: url("../public/KaLaTeXaDisplay/KaLaTeXaDisplay-Bold.woff2")
      format("woff2"),
    url("../public/KaLaTeXaDisplay/KaLaTeXaDisplay-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "KaLaTeXa Display";
  src: url("../public/KaLaTeXaDisplay/KaLaTeXaDisplay-Regular.woff2")
      format("woff2"),
    url("../public/KaLaTeXaDisplay/KaLaTeXaDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* KondolarThai */
@font-face {
  font-family: "Kondolar Thai";
  src: url("../public/KondolarThai/KondolarThai-Regular.woff2") format("woff2"),
    url("../public/KondolarThai/KondolarThai-Regular.woff") format("woff");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "Kondolar Thai";
  src: url("../public/KondolarThai/KondolarThai-Bold.woff2") format("woff2"),
    url("../public/KondolarThai/KondolarThai-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "KondolarThai-Regular";
  src: url("../public/KondolarThai/KondolarThai-Regular.woff2") format("woff2"),
    url("../public/KondolarThai/KondolarThai-Regular.woff") format("woff");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "KondolarThai-Bold";
  src: url("../public/KondolarThai/KondolarThai-Bold.woff2") format("woff2"),
    url("../public/KondolarThai/KondolarThai-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
body {
  margin: 0;
  font-family: "thongterm", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@import url("https://use.typekit.net/pbv5spi.css");

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

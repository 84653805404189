@font-face {
  font-family: 'IBM Plex Sans Thai Looped';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsansthailooped/v11/tss_AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30LxBI.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans Thai Looped';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsansthailooped/v11/tss6AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_OaphFk.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans Thai Looped';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsansthailooped/v11/tss6AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_K6vhFk.ttf) format('truetype');
}
* {
  box-sizing: border-box;
}
body {
  font-family: "Thongterm";
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}
button {
  outline: none;
  cursor: pointer;
}
.fr-popup .fr-layer {
  width: auto !important;
}
.fr-popup .fr-layer.fr-active {
  display: grid;
}
.fr-box.fr-basic .fr-element {
  border-radius: 5px;
  border: 1px solid #3BA7DD;
  background: #FFF;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.3) inset;
  margin: 0 8px;
  width: auto;
}
.fr-box.fr-basic .fr-element a,
.fr-box.fr-basic .fr-element button,
.fr-box.fr-basic .fr-element p,
.fr-box.fr-basic .fr-element h1,
.fr-box.fr-basic .fr-element h2,
.fr-box.fr-basic .fr-element h3,
.fr-box.fr-basic .fr-element h4,
.fr-box.fr-basic .fr-element h5,
.fr-box.fr-basic .fr-element h6,
.fr-box.fr-basic .fr-element input,
.fr-box.fr-basic .fr-element label,
.fr-box.fr-basic .fr-element span {
  font-family: "Thongterm";
  word-break: break-word;
}
.dropFileZone {
  border-radius: 5px;
  border: 1px dashed #3BA7DD;
  background: #fff;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  color: #000;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  cursor: pointer;
}
:not(iframe) a,
:not(iframe) button,
:not(iframe) p,
:not(iframe) h1,
:not(iframe) h2,
:not(iframe) h3,
:not(iframe) h4,
:not(iframe) h5,
:not(iframe) h6,
:not(iframe) input,
:not(iframe) label,
:not(iframe) span {
  font-family: "Thongterm";
}
.ant-tooltip-inner {
  font-family: "Thongterm";
}
.ant-select-item {
  font-family: "Thongterm";
}
.ant-form-item-control-input-content span {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  text-align: left;
  margin-top: 5px;
  display: block;
}
.ant-select.ant-select-in-form-item span {
  margin-top: 0;
}
.ant-form-item {
  margin-bottom: 12px;
}
.uploadFile .ant-row {
  display: grid;
}
.uploadFile .ant-row .ant-form-item-label {
  text-align: left;
}
.uploadAvatar .ant-row {
  display: grid;
}
.uploadAvatar .ant-row .ant-form-item-label {
  text-align: left;
}
.chatCharacter .ant-row {
  display: grid;
}
.chatCharacter .ant-row .ant-form-item-label {
  text-align: left;
}
.main--control {
  position: fixed;
  min-width: 100dvw;
  height: 100dvh;
  overflow: hidden;
  display: grid;
  grid-template-columns: 80px 285px 1fr 1fr;
  grid-template-rows: repeat(3, minmax(60px, auto));
  align-items: self-end;
  align-content: space-between;
  grid-gap: 0;
  font-family: 'KaLaTeXa Display', "Thongterm";
  grid-template-areas: "b a a a" "b d d d";
  background: #ececec;
}
.main--control a,
.main--control button,
.main--control p,
.main--control h1,
.main--control h2,
.main--control h3,
.main--control h4,
.main--control h5,
.main--control h6,
.main--control input,
.main--control label,
.main--control span {
  font-family: 'KaLaTeXa Display', "Thongterm";
}
.main--control .ant-tooltip-inner {
  font-family: 'KaLaTeXa Display', "Thongterm";
}
.main--control .ant-select-item {
  font-family: 'KaLaTeXa Display', "Thongterm";
}
.responsive--bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 57px;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.responsive--bar button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  height: 30px;
  cursor: pointer;
  opacity: 1;
}
.responsive--bar button span {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.responsive--bar button.btn--active {
  opacity: 1;
}
.responsive--bar button.btn--active span {
  color: #288aac;
  font-weight: bold;
}
.responsive--bar button.btn--active svg path {
  fill: #288aac;
}
.aside {
  grid-area: b;
  height: 100dvh;
  background-color: #333333;
  display: grid;
  grid-gap: 20px;
  padding: 12px 0;
  align-items: flex-start;
  align-content: flex-start;
  position: fixed;
  width: 80px;
  top: 0;
  z-index: 4;
}
.aside .home--btn button {
  padding: 0px;
  background: none;
  outline: none;
  border: none;
  font-size: 12px;
  display: grid;
  grid-gap: 4px;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  color: #fff;
  height: 100px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
}
.aside .menu ul {
  list-style: noe;
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin: 0;
  padding: 0;
}
.aside .menu ul li {
  list-style: none;
}
.aside .menu a {
  text-decoration: none;
  background: none;
  outline: none;
  border: none;
  font-size: 12px;
  display: grid;
  grid-gap: 4px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  color: #fff;
  height: 100px;
  font-weight: bold;
}
.aside .menu a svg {
  fill: #fff;
}
.aside .menu a svg path {
  fill: #fff;
}
.aside .menu a.active {
  background-color: #ECECEC;
  color: #000;
}
.aside .menu a.active svg {
  fill: #000;
}
.aside .menu a.active svg path {
  fill: #000;
}
.header--main {
  grid-area: a;
  height: 60px;
  background-color: #D9D9D9;
  position: relative;
  position: fixed;
  width: calc(100dvw - 80px);
  top: 0;
}
.header--main .menu--preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  height: 100%;
  padding: 12px;
}
.header--main .menu--preview a {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  display: grid;
  align-items: center;
  align-content: center;
  cursor: pointer;
  transition: all 0.5s;
}
.header--main .menu--preview .preview {
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 650;
  line-height: normal;
  border-radius: 50px;
  border: 1px solid #fff;
  text-decoration: none;
  padding: 12px 20px;
  height: 36px;
  display: flex;
  align-items: center;
}
.header--main .menu--preview .preview:hover {
  background-color: #124f64;
  transition: all 0.5s;
}
.header--main .menu--preview .save {
  border-radius: 50px;
  border: 1px solid #000;
  background: #2DD280;
  padding: 12px 20px;
  color: #00334D;
  font-size: 18px;
  font-style: normal;
  font-weight: 650;
  line-height: normal;
  height: 36px;
  display: flex;
  align-items: center;
}
.header--main .menu--preview .save:hover {
  background-color: #31c9ad;
  transition: all 0.5s;
}
.header--main .menu--action {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}
.header--main .menu--action h2 {
  margin: 0;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
}
.header--main .menu--action h2 button {
  border-radius: 50px;
  border: 1px solid #FFF;
  background: #000;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 650;
  line-height: 150%;
  cursor: pointer;
}
.header--main .menu--action .edit--box {
  display: flex;
  gap: 12px;
  padding: 12px;
}
.header--main .menu--action .edit--box input {
  width: 520px;
  display: block;
  max-width: 100%;
}
.header--main .menu--action .edit--box button {
  border-radius: 50px;
  border: 1px solid #FFF;
  background: #000;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 650;
  line-height: 150%;
  cursor: pointer;
}
.header--main .menu--action .user--menu {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  cursor: pointer;
}
.header--main .menu--action .user--menu .usr {
  display: flex;
  background: none;
  outline: none;
  border: none;
  padding: 0;
}
.header--main .menu--action .user--menu .usr img,
.header--main .menu--action .user--menu .usr svg {
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
  width: 32px;
  height: 32px;
}
.main--component {
  grid-area: d;
  height: calc(100dvh - 60px);
  position: relative;
  top: 60px;
  transition: all 0.5s;
  display: flex;
  overflow: hidden;
}
.main--component .template--layout {
  height: 100%;
  width: calc(100dvw - 410px);
  transition: all 0.5s;
  background-color: #fff;
  position: relative;
}
.main--component .template--layout .preview--template {
  height: 100%;
  overflow: hidden;
  position: fixed;
  width: calc(100% - 410px) !important;
  display: grid;
  background: linear-gradient(180deg, #FFF 0%, #B3B3B3 100%);
  padding: 0 30px;
  align-content: flex-start;
  justify-content: center;
}
.main--component .template--layout .preview--template iframe {
  max-width: calc(100% - 60px);
  background-color: #fff;
  margin: auto;
  height: calc(100vh - 300px) !important;
  transition: all 0.5s;
  border-radius: 20px;
  border: 1px dashed #666 !important;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.main--component .template--layout .template--page iframe {
  height: calc(100vh - 150px) !important;
}
.main--component .template--layout p {
  margin-top: 0;
  word-break: break-word;
  font-size: 16px;
  font-weight: 400;
  line-height: 2.25;
  letter-spacing: 0.25px;
}
.main--component .collapable--btn {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  position: absolute;
  left: 0;
  z-index: 3;
  top: 50%;
  transition: all 0.5s;
  background: #A8D8F0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  width: 20px;
  height: 55px;
  border-radius: 0px 4px 4px 0px;
}
.main--component .collapable--btn svg {
  transition: all 0.5s;
}
.main--component .collapable--bottom--btn {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  position: absolute;
  left: calc(50% - 30px);
  bottom: 0;
  transition: all 0.5s;
  background: #00334D;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  width: 55px;
  height: 20px;
  border-radius: 4px 4px 0px 0px;
}
.main--component .collapable--bottom--btn svg {
  transition: all 0.5s;
  transform: rotate(-90deg);
}
.main--component.aside--preview--collapse {
  transition: all 0.5s;
}
.main--component.aside--preview--collapse .template--layout {
  width: calc(100dvw - 50px);
}
.main--component.aside--preview--collapse .thumbnail--list {
  height: 100dvw;
}
.main--component.aside--preview--collapse .collapable--btn {
  left: 0px;
  transition: all 0.5s;
}
.main--component.aside--preview--collapse .collapable--btn svg {
  transition: all 0.5s;
  transform: rotate(-180deg);
}
.main--component.aside--preview--collapse .collapable--bottom--btn {
  left: calc(50% - 40px);
}
.main--component.aside--preview--collapse .template--layout .preview--template {
  width: calc(100% - 80px) !important;
}
.main--component.add--height {
  height: calc(100vh - 60px);
  margin-bottom: -60px;
  transition: all 0.5s;
  top: 0;
}
.main--component.add--height .template--layout .preview--template iframe {
  height: calc(100vh - 160px) !important;
  transition: all 0.5s;
}
.main--component.add--height .aside--preview {
  height: 100dvh;
}
.main--component.add--height .collapable--bottom--btn {
  bottom: 11px;
}
.main--component.add--height .collapable--bottom--btn svg {
  transition: all 0.5s;
  transform: rotate(90deg);
}
.aside--preview {
  height: 100dvh;
  background-color: #ECECEC;
  overflow-y: auto;
  padding: 12px;
  display: grid;
  align-content: flex-start;
  align-items: flex-start;
  transition: all 0.5s;
  transform: translateX(0);
  width: 330px;
  padding-bottom: 130px;
  grid-gap: 20px;
  flex: none;
}
.aside--preview .search--form {
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}
.aside--preview .search--form input {
  border-radius: 10px;
  padding: 4px 40px 4px 20px;
  font-family: "Thongterm";
  font-size: 18px;
  border: 1px solid #BABABA;
  width: 100%;
  outline: none;
}
.aside--preview .search--form button {
  border: none;
  background: none;
  outline: none;
  display: grid;
  align-items: center;
  align-content: center;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 0;
  height: 100%;
}
.aside--preview .module--card {
  cursor: pointer;
  display: grid;
  grid-gap: 10px;
}
.aside--preview .module--card .module--card--thumbnail {
  background-color: #fff;
  display: grid;
  border-radius: 10px;
  overflow: hidden;
  aspect-ratio: 16/9;
  font-size: 20px;
  align-items: center;
  align-content: center;
  text-align: center;
}
.aside--preview .module--card .module--card--thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.aside--preview .module--card .module--card--thumbnail strong {
  background: #00000099;
  width: 80%;
  margin: auto;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}
.aside--preview .module--card .module--card--name h2 {
  font-size: 14px;
  text-align: center;
  margin: 0;
}
.aside--preview.aside--preview--collapse {
  transition: all 0.5s;
  transform: translateX(-100%);
}
.aside--preview .expand--btn {
  display: flex;
}
.aside--preview .collapse--btn {
  display: none;
}
.aside--preview.expand {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  left: 0px;
  top: 0;
  z-index: 30;
}
.aside--preview.expand .fr-box.fr-basic.fr-top .fr-wrapper {
  padding: 12px;
}
.aside--preview.expand .fr-box.fr-basic .fr-element {
  width: 100%;
  margin: 0;
}
.aside--preview.expand .expand--btn {
  display: none;
}
.aside--preview.expand .collapse--btn {
  display: flex;
}
.preview--component {
  background-color: #00334D;
  height: 220px;
  display: flex;
  padding: 10px 22px 22px 22px;
  transition: all 0.5s;
  width: 100%;
  margin-left: 0;
  position: relative;
  z-index: 10;
  flex: none;
}
.preview--component .thumbnail--list {
  width: 130px;
  height: calc(100vw - 280px);
  overflow-y: auto;
  overflow-x: hidden;
  transform: rotate(-90deg) translateY(-130px);
  transform-origin: right top;
  padding-top: 70px;
}
.preview--component .thumbnail--list .display {
  transform: rotate(90deg) translateY(80px);
  transform-origin: right top;
  width: 178px;
  min-width: 100px;
  height: 82px;
  border-radius: 10px;
  object-fit: cover;
  align-items: center;
  align-content: center;
  text-align: center;
  font-size: 20px;
  position: relative;
  cursor: pointer;
  display: grid;
  padding-top: 10px;
}
.preview--component .thumbnail--list .display .section--display {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 650;
  line-height: 110%;
  text-align: left;
}
.preview--component .thumbnail--list .display .display--item {
  border-radius: 4px;
  border: 2px solid transparent;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px transparent;
  display: grid;
  position: relative;
  padding: 4px;
  width: 178px;
}
.preview--component .thumbnail--list .display .display--item .template--name--display p {
  margin: 0;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 650;
  line-height: 110%;
  text-align: left;
  /* 13.2px */
}
.preview--component .thumbnail--list .display .display--item .display--list {
  display: flex;
  gap: 8px;
}
.preview--component .thumbnail--list .display .display--item .thumbnail--display {
  aspect-ratio: 16/9;
  border-radius: 8px;
  width: 80px;
  overflow: hidden;
  flex: none;
  margin: 0;
}
.preview--component .thumbnail--list .display .display--item .thumbnail--display img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.preview--component .thumbnail--list .display .display--item .display--content p {
  text-align: left;
  margin: 0;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  /* 17.4px */
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
}
.preview--component .thumbnail--list .display:last-of-type {
  margin-top: 120px;
}
.preview--component .thumbnail--list .display img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.preview--component .thumbnail--list .display.selected .display--item {
  border: 2px solid #FF9243;
  box-shadow: 0px 0px 10px 0px #fff;
}
.preview--component .thumbnail--list .display.selected .section--display {
  color: #FF9243;
}
.preview--component .thumbnail--list .display.selected .action--btn {
  background-color: #FF9243;
}
.preview--component .thumbnail--list .current--chapter .action--btn {
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
  background-color: #00334D;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: grid;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.preview--component .thumbnail--list .current--chapter .action--btn svg path {
  fill: #fff;
}
.preview--component .thumbnail--list .current--chapter .action--btn a {
  width: 24px;
  height: 10px;
  display: grid;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
.preview--component span {
  position: fixed;
  background-color: #00334D;
  padding: 4px;
  bottom: 0px;
  width: calc(100% - 410px);
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 8px;
  align-items: center;
  align-content: center;
  color: #A8D8F0;
  font-size: 12px;
  font-style: normal;
  font-weight: 650;
  line-height: 110%;
  transition: all 0.5s;
  left: 410px;
}
.preview--component span:before,
.preview--component span:after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #A8D8F0;
  display: block;
}
.preview--component.aside--preview--collapse span {
  left: 80px;
  transition: all 0.5s;
  width: calc(100% - 80px);
}
.preview--component.go--down {
  margin-bottom: -150px;
  transition: all 0.5s;
}
.module--card--layout {
  display: grid;
  grid-gap: 4px;
  position: relative;
  border-radius: 8px;
  background: #fff;
  padding: 8px;
}
.module--card--layout .module--card--layout--thumbnail {
  background-color: #fff;
  display: grid;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  border: 1px solid #D9D9D9;
}
.module--card--layout .module--card--layout--thumbnail img {
  aspect-ratio: 16/9;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.module--card--layout .module--card--layout--name h2 {
  font-size: 14px;
  color: #000;
  margin: 0;
  font-style: normal;
  font-weight: 650;
  line-height: 110%;
  text-align: left;
  /* 15.4px */
}
.module--card--layout .module--card--layout--button {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4px;
}
.module--card--layout .module--card--layout--button .preview {
  border-radius: 50px;
  border: 1px solid #00334D;
  display: flex;
  height: 28px;
  padding: 6px 12px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #00334D;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.module--card--layout .module--card--layout--button .selected {
  border: 1px solid #00334D;
  border-radius: 50px;
  background: #00334D;
  display: flex;
  height: 28px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 1522px) {
  .module--card--layout .module--card--layout--button .preview,
  .module--card--layout .module--card--layout--button .selected {
    font-size: 11px;
  }
}
.setting--tab {
  position: relative;
  width: 100%;
  background-color: #A8D8F0;
  display: grid;
  container-type: inline-size;
  container-name: sidebar;
  transition: all 0.5s;
}
.setting--tab input,
.setting--tab textarea {
  font-family: "Thongterm";
}
.setting--tab .action--btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.setting--tab .action--btn button {
  background: none;
  border: none;
  outline: none;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  align-content: center;
  gap: 10px;
  cursor: pointer;
}
.setting--tab .slide--name {
  display: grid;
  margin-bottom: 8px;
}
.setting--tab .slide--name button {
  display: block;
  text-align: center;
  background: none;
  border: none;
  color: #00334D;
  font-size: 18px;
  font-style: normal;
  font-weight: 650;
  line-height: 110%;
  padding-bottom: 4px;
  border-bottom: 1px solid #000;
}
.setting--tab .slide--name label {
  color: #00334D;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 4px;
  align-items: center;
  align-content: center;
}
.setting--tab .slide--name label:before,
.setting--tab .slide--name label:after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #000;
  display: block;
}
.setting--tab .form {
  display: grid;
  align-content: flex-start;
}
.setting--tab .form iframe {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
  margin-top: 20px;
  border: none;
}
.setting--tab .form hr {
  border-color: #00334D;
  margin: 20px 0 0;
}
.setting--tab .form h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  text-align: left;
  margin: 22px 0 15px;
}
.setting--tab .form textarea {
  border-radius: 5px;
  border: 1px solid #3BA7DD;
  background: #FFF;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.3) inset;
}
.setting--tab .form select option {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 10px;
  align-items: flex-start;
  align-content: flex-start;
}
.setting--tab .form .color--picker .ant-form-item-control-input-content {
  display: grid;
  justify-content: flex-start;
}
.setting--tab .form .char--remind {
  font-size: 8px;
  font-weight: 500;
  color: #A9A9A9;
  height: 19px;
  padding: 6px;
  border-radius: 3px;
  border: 1px solid #A9A9A9;
  width: auto;
  margin-left: 0;
  margin-right: auto;
  float: right;
  margin-left: auto;
  margin-top: 10px;
  text-align: right;
}
.setting--tab .form label {
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 650;
  line-height: 1.6;
  white-space: break-spaces;
  align-items: flex-start;
  height: auto;
}
.setting--tab .form label span {
  color: #FF0F0F;
}
.setting--tab .form label strong {
  color: #000;
}
.setting--tab .form .add--more {
  width: 100%;
  height: 48px;
  border-radius: 10px;
  color: #fff;
  background-color: #28ac92;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  display: grid;
  align-items: center;
  align-content: center;
  border: none;
  margin-bottom: -24px;
  cursor: pointer;
}
.setting--tab .form .add--more:hover {
  background-color: #1c7765;
  color: #fff;
}
.setting--tab .form .add--item {
  border-radius: 50px;
  background: #00334D;
  border: none;
  width: 100%;
  height: 36px;
  padding: 12px;
  outline: none;
  display: flex;
  gap: 5px;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
.setting--tab .form .add--item span {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 650;
  line-height: 150%;
  margin-top: 0;
}
.setting--tab .form .add--next--point {
  border-radius: 50px;
  background: #00334D;
  border: none;
  width: 100%;
  height: 36px;
  padding: 12px;
  outline: none;
  display: flex;
  gap: 5px;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
.setting--tab .form .add--next--point span {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 650;
  line-height: 150%;
  margin-top: 0;
}
.setting--tab .form .add--next--point:hover {
  background-color: #00334D;
}
.setting--tab .form .add--next--point:hover span {
  color: #fff;
}
.setting--tab .form .slide--list {
  display: grid;
  grid-gap: 10px;
  margin: 10px auto;
}
.setting--tab .form .slide--list .slide--list--item {
  display: grid;
  align-items: center;
  width: 100%;
  grid-template-columns: 24px 1fr 20px;
  grid-gap: 4px;
}
.setting--tab .form .slide--list .slide--list--item svg {
  margin-top: 4px;
}
.setting--tab .form .slide--list .slide--list--item h4 {
  width: 100%;
  height: auto;
  border-radius: 10px;
  padding: 4px 16px;
  margin: 0;
  text-align: left;
  border-radius: 5px;
  border: 1px solid #00334D;
  color: #00334D;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  cursor: pointer;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  word-break: break-word;
  max-height: 43px;
}
.setting--tab .form .slide--list .slide--list--item h4.active {
  border: 1px solid #FF9243;
  background: #FF9243;
}
.setting--tab .form .slide--list .slide--list--item .remove {
  background: none;
  border: none;
  padding: 0;
  outline: none;
  display: grid;
  align-items: center;
  align-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.setting--tab .form .slide--list .slide--list--item .remove svg {
  margin-top: 6px;
}
.setting--tab .form .browseFile {
  position: relative;
  overflow: hidden;
}
.setting--tab .form .browseFile input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
  left: 0;
}
.setting--tab .form .browseFile span {
  z-index: 1;
  position: relative;
  font-size: 16px;
  color: #000;
  background-color: #fff;
  border-radius: 4px;
  width: 100px;
  display: grid;
  height: 28px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid #000;
}
.setting--tab .ant-form-item-control-input-content {
  width: 100%;
  transition: all 0.5s;
}
.setting--tab.expand .expand--btn {
  display: none;
}
.setting--tab.expand .collapse--btn {
  display: flex;
}
.setting--tab.expand .ant-form-item-control-input-content {
  width: 100%;
  max-width: 100%;
  transition: all 0.5s;
}
.ant-dropdown-menu-title-content {
  display: grid;
}
.ant-dropdown-menu-title-content a {
  display: grid;
  align-items: center;
  align-content: center;
  grid-template-columns: 24px 1fr;
  grid-gap: 8px;
  font-family: 'KaLaTeXa Display', "Thongterm";
}
.dropFileZone {
  width: 100%;
  height: 150px;
  border: 2px solid #ddd;
  border-radius: 5px;
  padding: 5px;
}
.dropFileZone.hover {
  border: 2px dashed #1890ff;
  background-color: #e6f7ff;
}
.blur--bg {
  box-shadow: 0px 0px 3px 2px rgba(255, 255, 255, 0.7);
  background: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
}
.addSlide {
  transform: rotate(90deg);
  transform-origin: right top;
  cursor: pointer;
  width: 35px;
  height: 90px;
  margin: 0;
  margin-top: 109px;
  margin-bottom: -46px;
  margin-left: 58px;
  position: relative;
  top: -145px;
  border-radius: 8px;
  border: 2px solid #00334D;
  background: #A8D8F0;
  display: grid;
  align-items: center;
  justify-content: center;
}
.addSlide:hover {
  background-color: #56a1c6;
}
.blank {
  transform: rotate(90deg);
  transform-origin: right top;
  cursor: pointer;
  width: 35px;
  height: 90px;
  margin: 0;
  margin-top: 109px;
  margin-bottom: -46px;
  margin-left: 58px;
  position: relative;
  top: -145px;
  border-radius: 8px;
  border: 2px solid #00334D;
  background: #A8D8F0;
  display: grid;
  align-items: center;
  justify-content: center;
}
.blank:hover {
  background-color: #56a1c6;
}
.scroll--overflow {
  position: absolute;
  bottom: 0;
  width: 330px;
  height: 50px;
  pointer-events: none;
  z-index: 3;
  background: linear-gradient(180deg, rgba(168, 216, 240, 0) 0%, rgba(168, 216, 240, 0.7) 50%, #A8D8F0 100%);
}
.image--list ul {
  display: flex;
  gap: 4px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.image--list ul li {
  list-style: none;
  display: grid;
}
.image--list ul li button {
  color: #82B2C9;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  display: grid;
  background-color: #00334D;
  border-radius: 5px 5px 0px 0px;
  align-items: center;
  align-content: center;
  padding: 8px 8px 4px 8px;
}
.image--list ul li button.active {
  background-color: #fff;
  color: #000;
}
.image--list .images--item--list {
  display: grid;
  background-color: #fff;
  padding: 8px;
  border-radius: 0 5px 5px 5px;
}
.image--list .images--item--list .item .ant-badge-count {
  border-radius: 5px;
  border: 1px solid #FFF;
  background: rgba(0, 0, 0, 0.5);
  top: auto;
  bottom: 17px;
  transform: none;
  right: 10px;
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.image--list .images--item--list .item img {
  width: 100%;
  height: auto;
}
.image--list .images--item--list .item img.active {
  border: 2px solid #FF9243;
}
.image--list .images--item--list .item img.unactive {
  border: 2px solid transparent;
}
.image--list .images--item--list .item .close--btn {
  position: absolute;
  left: -4px;
  top: -4px;
  display: grid;
}
.image--upload--container {
  display: grid;
  grid-gap: 12px;
}
.form--multiple--chapter {
  display: grid;
  grid-gap: 12px;
}
@container sidebar (min-width: 700px) {
  .form--multiple--chapter {
    grid-template-columns: 300px 1fr;
  }
  .form--multiple--chapter .chapter--table {
    padding-right: 20px;
    border-right: 1px solid #00334D;
  }
  .form--multiple--chapter .chapter--table hr {
    display: none;
  }
  .image--upload--container {
    grid-template-columns: 200px 1fr;
  }
  .dropFileZone {
    height: 200px;
  }
  .image--list .images--item--list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    height: 60vh;
    overflow-y: auto;
  }
}
.select--template .ant-modal-content {
  border-radius: 16px;
  border: 5px solid #FFF;
  background: #FFF;
  padding: 0;
}
.select--template .ant-modal-header {
  padding: 20px 0px;
  background: #000;
  margin: 0;
}
.select--template .ant-modal-header .ant-modal-title {
  color: #FFF;
  font-family: "KaLaTeXa Display";
  font-size: 25px;
  font-style: normal;
  font-weight: 650;
  line-height: 110%;
  text-align: center;
}
.select--template .ant-modal-close {
  width: 30px;
  height: 30px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 30px;
}
.select--template .ant-modal-close:hover {
  color: #fff;
}
.select--template .template--list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 750px;
  padding: 16px;
  align-items: flex-start;
  align-content: flex-start;
  grid-gap: 16px;
  height: calc(100vh - 300px);
  overflow-y: auto;
}
.select--template .template--list .template--item {
  display: grid;
  padding: 16px;
  grid-gap: 12px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  border: 2px solid #FFF;
  background: #FFF;
  cursor: pointer;
  transition: all 0.5s;
}
.select--template .template--list .template--item:hover {
  border: 2px solid #FF9243;
  box-shadow: 0px 0px 10px 0px #E57300;
}
.select--template .template--list .template--item h3 {
  color: #000;
  font-family: "KaLaTeXa Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 650;
  line-height: 110%;
  margin: 0;
}
.select--template .template--list .template--item img {
  border-radius: 5px;
  border: 1px solid #CCC;
  background: #fff;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}
.select--template .layout--list {
  padding: 16px;
}
.select--template .layout--list img {
  border-radius: 5px;
  border: 1px solid #CCC;
  background: #fff;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}
.select--template .ant-modal-confirm-btns {
  display: flex !important;
  justify-content: center;
}
.select--template .ant-modal-confirm-btns .ant-btn.ant-btn-primary {
  width: 200px;
  margin: 0;
}
.select--template .ant-modal-footer {
  display: none;
}
.select--template .action--btn {
  border-radius: 50px;
  background: #00334D;
  width: 200px;
  height: 36px;
  display: grid;
  align-items: center;
  align-content: center;
  padding: 12px;
  color: #FFF;
  font-family: "KaLaTeXa Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 650;
  line-height: 150%;
}
.select--template .ant-modal-content {
  overflow: hidden;
}
.select--template .ant-modal-confirm-body-wrapper .ant-modal-confirm-paragraph {
  margin-top: 0;
}
.select--template .ant-modal-confirm-body-wrapper .ant-modal-confirm-paragraph .ant-modal-confirm-title {
  padding: 20px 0px;
  background: #000;
  margin: 0;
  color: #FFF;
  font-family: "KaLaTeXa Display";
  font-size: 25px;
  font-style: normal;
  font-weight: 650;
  line-height: 110%;
  text-align: center;
  margin: -25px -20px 0;
}
.ant-modal-confirm-confirm .ant-modal-confirm-body,
.ant-modal-confirm.ant-modal-confirm-info .ant-modal-confirm-body {
  display: grid;
}
.ant-modal-confirm-confirm .anticon,
.ant-modal-confirm.ant-modal-confirm-info .anticon {
  display: none;
}
.ant-modal-confirm-confirm .ant-modal-confirm-paragraph,
.ant-modal-confirm.ant-modal-confirm-info .ant-modal-confirm-paragraph {
  width: 100%;
  display: grid;
  max-width: 100%;
  text-align: center;
  margin-top: -50px;
}
.ant-modal-confirm-confirm .ant-modal-confirm-paragraph .ant-modal-confirm-content,
.ant-modal-confirm.ant-modal-confirm-info .ant-modal-confirm-paragraph .ant-modal-confirm-content {
  color: #00334D;
  font-family: "KaLaTeXa Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}
.ant-modal-confirm-confirm .ant-modal-content,
.ant-modal-confirm.ant-modal-confirm-info .ant-modal-content {
  display: grid;
  padding: 20px;
  align-items: center;
  border-radius: 20px;
  border: 5px solid #00334D;
  background: #FFF;
}
.ant-modal-confirm-confirm .ant-modal-confirm-btns,
.ant-modal-confirm.ant-modal-confirm-info .ant-modal-confirm-btns {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 20px;
}
.ant-modal-confirm-confirm .ant-modal-confirm-btns .ant-btn-default,
.ant-modal-confirm.ant-modal-confirm-info .ant-modal-confirm-btns .ant-btn-default {
  border-radius: 50px;
  border: 1px solid #00334D;
  background-color: #fff;
  color: #00334D;
  font-size: 18px;
  font-style: normal;
  font-weight: 650;
  line-height: 150%;
  height: auto;
  font-family: "KaLaTeXa Display";
}
.ant-modal-confirm-confirm .ant-modal-confirm-btns .ant-btn-default span,
.ant-modal-confirm.ant-modal-confirm-info .ant-modal-confirm-btns .ant-btn-default span {
  font-family: "KaLaTeXa Display";
}
.ant-modal-confirm-confirm .ant-modal-confirm-btns .ant-btn-default:hover,
.ant-modal-confirm.ant-modal-confirm-info .ant-modal-confirm-btns .ant-btn-default:hover {
  color: inherit;
  border-color: inherit;
}
.ant-modal-confirm-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn,
.ant-modal-confirm.ant-modal-confirm-info .ant-modal-confirm-btns .ant-btn + .ant-btn,
.ant-modal-confirm-confirm .ant-modal-confirm-btns .preview--btn,
.ant-modal-confirm.ant-modal-confirm-info .ant-modal-confirm-btns .preview--btn {
  border-radius: 50px;
  border: 1px solid #00334D;
  background-color: #00334D;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 650;
  line-height: 150%;
  height: auto;
  font-family: "KaLaTeXa Display";
  text-align: center;
}
.ant-modal-confirm-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn span,
.ant-modal-confirm.ant-modal-confirm-info .ant-modal-confirm-btns .ant-btn + .ant-btn span,
.ant-modal-confirm-confirm .ant-modal-confirm-btns .preview--btn span,
.ant-modal-confirm.ant-modal-confirm-info .ant-modal-confirm-btns .preview--btn span {
  font-family: "KaLaTeXa Display";
}
@media (max-width: 1522px) {
  .desktop--view .main--control {
    grid-template-columns: 80px 16dvw 1fr 1fr;
  }
  .desktop--view .template--layout {
    width: calc(100dvw - 22.5dvw);
  }
  .desktop--view .template--layout .preview--template {
    width: calc(100dvw - 22.5dvw) !important;
    padding: 0;
  }
  .desktop--view .template--layout .preview--template iframe {
    max-width: 100dvw;
    transform: scale(0.7);
    width: 100dvw;
    height: calc(100dvh - 13dvh) !important;
    top: -13dvh;
    position: relative;
  }
  .desktop--view .template--layout .template--page iframe {
    height: calc(100dvh + 80px) !important;
  }
  .desktop--view .aside--preview {
    width: auto;
  }
  .desktop--view .aside--preview.expand {
    width: 100dvw;
  }
  .desktop--view .preview--component span {
    width: calc(100dvw - 22.5dvw);
    left: 22.5dvw;
  }
}
.desktop--view.add--height .template--layout .preview--template iframe {
  height: calc(100dvh + 10dvh) !important;
  transition: all 0.5s;
  top: -16dvh;
}
.loading {
  position: absolute;
  width: 100%;
  height: calc(100vh - 300px);
  display: grid;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  pointer-events: none;
}
.loading .loader {
  width: 45px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#00334D 0 0);
  background: var(--c) 0% 50%, var(--c) 50% 50%, var(--c) 100% 50%;
  background-size: 20% 100%;
  animation: l1 1s infinite linear;
}
@keyframes l1 {
  0% {
    background-size: 20% 100%, 20% 100%, 20% 100%;
  }
  33% {
    background-size: 20% 10%, 20% 100%, 20% 100%;
  }
  50% {
    background-size: 20% 100%, 20% 10%, 20% 100%;
  }
  66% {
    background-size: 20% 100%, 20% 100%, 20% 10%;
  }
  100% {
    background-size: 20% 100%, 20% 100%, 20% 100%;
  }
}
.media--section .ant-form-item-label {
  padding: 0;
}
.media--section .ant-form-item-control-input-content {
  display: grid;
}
.media--section .media--list {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 12px;
}
.media--section .media--list label span {
  margin: 0;
  color: #00334D;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
}
.img--size--box {
  display: grid;
  grid-gap: 12px;
}
.img--size--box .check--box--value {
  display: grid;
  margin-top: 12px;
}
.img--size--box .check--box--value label span {
  margin: 0;
  color: #00334D;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
}
.img--size--box .toggle--tab {
  display: grid;
  grid-gap: 6px;
}
.img--size--box .toggle--tab ul {
  display: flex;
  gap: 4px;
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 2px solid #fff;
  justify-content: center;
}
.img--size--box .toggle--tab ul li {
  list-style: none;
  display: grid;
}
.img--size--box .toggle--tab ul li button {
  color: #00334D;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  display: flex;
  gap: 10px;
  border: 1px solid #00334D;
  background-color: transparent;
  border-radius: 5px 5px 0px 0px;
  align-items: center;
  align-content: center;
  padding: 8px 8px 4px 8px;
  border-bottom: #fff;
}
.img--size--box .toggle--tab ul li button.active {
  background-color: #fff;
  color: #000;
}
.img--size--box h2 {
  font-size: 14px;
  font-weight: 650;
  text-align: left;
  margin: 0;
}
.img--size--box p {
  text-align: left;
  margin: 0;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 13.2px */
}
.img--size--box p span {
  margin: 0;
}
.ant-modal-content {
  font-family: "Thongterm";
}
.ant-modal-content li {
  font-size: 18px;
}
.ant-modal-content .ant-modal-title {
  font-size: 20px;
}
.importChat textarea {
  font-family: "Thongterm";
  font-size: 16px;
}
.importChat .ant-modal-footer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
.importChat .ant-modal-footer button {
  outline: none;
  border: 1px solid #000;
  border-radius: 4px;
  background-color: #fff;
  font-family: "Thongterm";
  width: 80px;
}
.importChat .ant-modal-footer .onOk {
  color: #fff;
  font-weight: 600;
  background-color: #1C4587;
}

.aside {
  background-color: #00334D;
}
.aside .menu a.active {
  background-color: #A8D8F0;
}
.aside .menu a.active svg path {
  fill: #00334D;
}
.header--main {
  background-color: #000000;
}
.header--main .menu--action h2 {
  color: #fff;
}
.header--main .menu--action .user--menu {
  color: #fff;
  font-style: normal;
  font-weight: 650;
  line-height: normal;
}
.header--main .menu--preview {
  background-color: #00334D;
}
.aside--preview {
  background-color: #A8D8F0;
  position: relative;
}
.responsive--bar button.btn--active span {
  color: #FF9243;
}
.responsive--bar button.btn--active svg path {
  fill: #FF9243;
}
